<template>
  <div class="ma-3">
    <v-row class="mb-n14 ma-1">
      <v-col
        cols="12"
        sm="6"
      >
        <date-picker
          :format-date="formatDate"
          :label="'Tanggal Awal'"
          :date-value="listQuery.start_date"
          :value-date.sync="listQuery.start_date"
          @changeDate="listQuery.start_date = $event"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <date-picker
          :format-date="formatDate"
          :label="'Tanggal Akhir'"
          :date-value="listQuery.end_date"
          :value-date.sync="listQuery.end_date"
          @changeDate="listQuery.end_date = $event"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-n10 ma-4">
        <search
          :list-query="listQuery"
          :handle-search="handleSearch"
        />
      </v-col>
    </v-row>
    <v-row class="ma-1">
      <v-col
        cols="12"
        sm="6"
      >
        <v-btn
          block
          color="primary"
          class="btn-search"
          @click="handleSearch"
        >
          {{ $t('search') }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-btn
          block
          color="#4f4f4f"
          class="btn-reset"
          @click="onReset"
        >
          {{ $t('reset') }}
        </v-btn>
      </v-col>
    </v-row>
    <costume-card
      icon="mdi-clipboard-text"
      title="List Izin Pegawai"
      class="px-5 py-0"
    >
      <table-component
        :list="list"
        :query="listQuery"
        :limit="listQuery.limit"
        :table-headers="tableHeader"
      />
      <pagination
        :total="totalPage"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        :on-next="onNext"
      />
    </costume-card>
  </div>
</template>

<script>
  export default {
    name: 'ListDayOff',
    data: () => ({
      list: [],
      totalPage: 0,
      isRefresh: false,
      formatDate: 'DD/MM/YYYY',
      listQuery: {
        search: '',
        start_date: '',
        end_date: '',
        limit: 10,
        page: 1,
      },
      tableHeader: [
        { text: 'Nama Pegawai', value: 'fullname', sortable: false },
        { text: 'Jenis Izin', value: 'permits_type', sortable: false },
        { text: 'Tanggal Mulai Izin', value: 'start_date' },
        { text: 'Tanggal Akhir Izin', value: 'end_date' },
        { text: 'Alasan', value: 'note', width: 100 },
        { text: 'Link File Evidence', value: 'file_url' },
      ],
    }),
    watch: {
      isRefresh (value) {
        if (value) {
          this.handleSearch()
          this.isRefresh = false
        }
      },
      'listQuery.search' (value) {
        if ((value === undefined) && (value.length <= 2)) return
        this.listQuery.page = 1
        this.handleSearch()
      },
    },
    async mounted () {
      await this.handleSearch()
    },
    methods: {
      async handleSearch () {
        const response = await this.$store.dispatch('dayoff/getListDayOff', this.listQuery)
        this.totalPage = response._meta.totalPage
        if (response.results) {
          this.list = response.results
        }
      },
      async onNext () {
        await this.handleSearch()
      },
      async onReset () {
        this.listQuery.search = ''
        this.listQuery.start_date = ''
        this.listQuery.end_date = ''
        await this.handleSearch()
      },
    },
  }
</script>
